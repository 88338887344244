.ValidationCodeInput {
  height: 4.5rem;
  width: 4.5rem;
  padding: 0;
  font-size: 24px;
  text-align: center;
  margin-right: 12px;
  text-transform: uppercase;
  color: #494949;
  border: 0px;
  border-radius: 4px;
  background-clip: padding-box;
  background: #f9f9f9;
  border-radius: 8px;
  margin-top: 1.8rem;
  border-bottom: 2px solid transparent;
}

.ValidationCodeInput:focus {
  outline: 0;
  background-image: (0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
  background: #f9f9f9;
  /* border-bottom: 2px solid #ffb13d; */
  border-bottom-color: #ffb13d;
}

.ValidationCodeInput[value]:not([value='']):not(:focus) {
  background: #ffb13d;
  color: #ffffff;
}
