.LocalFooter {
  /* margin: 10rem 0 1rem 0; */
  margin: 4rem 0 0rem 0;
  font-size: 24px;
}

.ContainerBox {
  margin: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
