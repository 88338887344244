.Logo {
  height: 3rem;
  margin: 1.5rem 2rem;
}

.AccountVariant {
  padding: 8px 5%;
  width: 100%;
  /* height: calc(100vh - 6.5rem);
  display: flex;
  align-items: center; */
}

ul {
  padding-inline-start: 10px;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
