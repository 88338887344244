::-webkit-scrollbar {
    width: .75em;
    margin-right: .5em;
  }
 
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  
  ::-webkit-scrollbar-thumb {
    background: rgb(255, 153, 0); 
    border-radius: 2em;
  }
  
  
  ::-webkit-scrollbar-thumb:hover {
    background:  rgba(255, 153, 0, .5); 
  }
  

.stripeHolder
{
    position: absolute;
    top: 50%;
    left: 50%;
    height: 55%;
    transform: translate(-50%, -50%);
    width: 20%;
    background-color: #ffffff;
    border-radius:1em;
    box-shadow: 24;
    padding:1em;
    display: flex;
    justify-content: center;
}

@media screen and (max-height: 800px ){
    .stripeHolder{
        height:100%;
        width: 30%;
        overflow-y: auto;
        display: block;
    }
}