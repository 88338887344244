.ContainerBox {
  margin: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

@media (max-width: 1180px) {
  label {
    width: 110px;
  }
}
