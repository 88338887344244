.payment-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.form-left,
.form-right {
  flex: 1;
}

.cardStyle {
  display: flex;
}

.cardWidth {
  width: 50%;
}

label {
  display: block;
  font-weight: bold;
}

input,
.payment-form .card-element {
  width: 100%;
  margin-left: 0%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  margin-top: 5px;
}

.card-element-container {
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  margin-top: 5px;
}

.pay-button-container {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  width: 100%; /* Ensure the container takes full width */
  margin-top: 20px; /* Add some spacing from the billing details */
}

.pay-button:hover {
  background-color: #45a049;
}

.apply-coupon-button {
  background-color: #007bff;
  color: white;
  margin-left: 1%;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
}

.apply-coupon-button:hover {
  background-color: #0056b3;
}

.billing-details {
  background-color: #f9f9f9;
  padding: 5px;
  border-radius: 5px;
}

.billing-rows {
  font-size: small;
  margin-top: 1%;
  background-color: #f9f9f9;
  padding: 1em;
  border-radius: 1em;
}

.billing-row {
  display: flex;
  justify-content: space-between;
  padding-bottom: .5em;
}

.billing-row.total {
  font-weight: bold;
  padding-top: .5em;
}

.action-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px; /* Add some spacing from the billing details */
}

.pay-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: auto; /* Align the button to the right */
}
.disable-button {
  background-color: gray;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: not-allowed;
  margin-left: auto; /* Align the button to the right */
}
