.SideButton {
  color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 45px;
  margin-top: 15px;
  margin-bottom: 15px;
  /* padding-top: 40.32%; /* (img-height / img-width * container-width) */
  /* (853 / 1280 * 100) */
}

.SideButton.Selected {
  color: #0a2435;
  height: 129px;
  margin-top: -15px;
  margin-bottom: -15px;
  /* padding-top: 52.01%; /*(img-height / img-width * container-width) */
  /* (853 / 1280 * 100) */
}
