.ContainerBox {
  margin: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.MiniTitle {
  margin-bottom: 1.9rem !important;
}

.heading {
  color: #0a2435;
}

.LocalFooter {
  /* margin: 10rem 0 1rem 0; */
  margin: 4rem 0 0rem 0;
}

.TandC {
  text-decoration: none;
  font-weight: bold;
  color: dimgray;
}

.TandC:visited {
  text-decoration: none;
  font-weight: bold;
  color: dimgray;
}

.TandcHolder {
  width:100%;
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 1em !important;
  margin-bottom: -1.5em !important;
}

.TandcHolder .tandcfont {
  font-size: 0.8rem !important;
}

.PasswordTextHolder {
  margin-top: -1em !important;
  font-size: 0.8rem !important;
}