.LocalFooterButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PlanCardBase {
  border-radius: 1rem !important;
  box-shadow: none !important;
}

.UnselectedPlanCard {
  border: 1px solid #e3e7ec;
}

.UnselectedPlanCard:hover {
  background: #f9f9f9;
}

.SelectedPlanCard {
  /* border: 3px solid; */
}

.PlanCardHeader {
  background: rgba(129, 129, 129, 0.06);
  border-top: 6px solid #f09418;
}

.PlanCardVennFlow {
  border-color: #c6c5c5;
}

.PlanCardVennFlowPlus {
  border-color: #e5c89a;
}

.PlanCardVennFlowPlusPlus {
  border-color: #ffeda8;
}

.Connector {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(10, 36, 53, 0.06) !important;
  background-color: #fff;
  padding: 18px 15px;
  position: relative;
}

.Connector h6 {
  height: 55px;
}

.Connector h6 img {
  width: 54px;
}

.Connector p {
  margin-top: 7px !important;
}
.Connector button,
.ConnectButton {
  letter-spacing: -0.01em;
  color: #f09318;
  font-size: 15px !important;
  text-transform: capitalize;
  min-width: 100%;
}

.Disabled {
  color: rgba(10, 36, 53, 0.2) !important;
  cursor: not-allowed !important;
}

.checkStatus {
  position: absolute;
  left: 50%;
  margin-top: 12px;
}

.ButtonDashboard {
  min-width: inherit !important;
  padding: 5px 19px !important;
  text-transform: none !important;
}

.ButtonMoreData {
  text-transform: none !important;
  border-radius: 8px !important;
  padding: 5px 19px !important;
  color: #f6913d !important;
}

.FinishPopupHeading {
  font-weight: 500;
  font-size: 17px;
  display: block;
}

.FinishPopupTip {
  font-size: 14px;
  color: #8f8f8f;
  display: block;
  margin-top: 20px;
}

.TandC {
  text-decoration: none;
  font-weight: bold;
  color: dimgray;
}

.TandC:visited {
  text-decoration: none;
  font-weight: bold;
  color: dimgray;
}

.TandcHolder {
  width:100%;
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 1em !important;
  margin-bottom: -1.5em !important;
}

.TandcHolder .tandcfont {
  font-size: 0.8rem !important;
}

.PasswordTextHolder {
  margin-top: -1em !important;
  font-size: 0.8rem !important;
}