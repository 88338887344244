.Logo {
  height: 3rem;
  margin: 1.5rem 2rem;
  /* align-self: center; */
}

/* fix height of the signup form */
.heightFix {
  height: calc(100vh - 6.5rem); /* calc(110vh); */
}

.AccountVariant {
  /* padding: 5% 10%; */
  padding: 0rem 7rem;
}

@media (max-width: 768px) {
  .AccountVariant {
    padding: 1rem;
  }
  .heightFix {
    height: calc(100vh - 13.5rem);
    text-align: center;
  }
  .logoWrap {
    text-align: center;
  }
}
